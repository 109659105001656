export interface Industry {
    id:number,
    industryName: string;
    industryImage: string;
    industryDetail: string;
    url?: string;
}

export const industriesData:Industry[] = [
  {
    id:1,
    industryName: 'industries.industry.telco_retail',
    industryImage: 'Telco-retail.png',
    industryDetail: 'industries.telco_retail.industryDetail',
  },
  {
    id:2,
    industryName: 'industries.industry.food_retail',
    industryImage: 'Food-retail.png',
    industryDetail: 'industries.food_retail.industryDetail',
  },
  {
    id:3,
    industryName: 'industries.industry.high_street',
    industryImage: 'High-street.png',
    industryDetail: 'industries.high_street.industryDetail'
  },
  {
    id:4,
    industryName: 'industries.industry.museums',
    industryImage: 'Museums.png',
    industryDetail: 'industries.museums.industryDetail',
    url: 'museums'
  }
]

