<ng-container *ngIf="isDialog else formTemp">
  <div *ngIf="data && data.isOpen" class="pyg-dialog-bg">
    <div class="pyg-dialog-form" (clickOutside)="onCloseDialog()">
      <ng-template [ngTemplateOutlet]="formTemp"></ng-template>
    </div>
  </div>
</ng-container>

<ng-template #formTemp>
  <ng-container *ngIf="data">
    <ng-container *ngIf="status !== 'success' else successTemp">
      <h2>{{ data.title| translate }}</h2>
      <p *ngIf="data.subtitle" class="pyg-subtitle">{{ data.subtitle | translate }}</p>
      <p class="py-4 sm:pb-8 pyg-get-questions">{{ 'freetrial.get_questions' | translate }}</p>
      <form [formGroup]="demoForm" (clickOutside)="outsideForm()">
        <label>{{ 'freetrial.name' | translate }}</label>
        <input
          type="text"
          formControlName="name"
          autocomplete="given-name"
          [style.border]="name?.invalid && name?.touched ? '2px solid red' : '2px solid #D6D2DC'"
        >
        <p
          *ngIf="name?.invalid && name?.touched"
          class="pygMessage">
          {{ 'freetrial.name_warn' | translate }}
        </p>

        <label>{{ 'freetrial.lastname' | translate }}</label>
        <input
          type="text"
          formControlName="surname"
          autocomplete="family-name"
          [style.border]="surname?.invalid && surname?.touched ? '2px solid red' : '2px solid #D6D2DC'"
        >
        <p
          *ngIf="surname?.invalid && surname?.touched"
          class="pygMessage">
          {{ 'freetrial.lastname_warn' | translate }}
        </p>

        <label>{{ 'freetrial.email_bussines' | translate }}</label>
        <input
          type="email"
          formControlName="email"
          autocomplete="email"
          placeholder="@"
          [style.border]="email?.invalid && email?.touched ? '2px solid red' : '2px solid #D6D2DC'"
        >
        <p
          *ngIf="email?.invalid && email?.errors?.['required'] && email?.touched"
          class="pygMessage">
            {{ 'freetrial.email_warn' | translate }}
        </p>
        <p
          *ngIf="email?.invalid && email?.errors?.['unwantedDomain'] && email?.touched"
          class="pygMessage">
            {{ 'freetrial.email_warn_bussines' | translate }}
        </p>

        <label>{{ 'freetrial.company' | translate }}</label>
        <input
          type="text"
          formControlName="company"
          autocomplete="organization"
          [style.border]="company?.invalid && company?.touched ? '2px solid red' : '2px solid #D6D2DC'"
        >
        <p
          *ngIf="company?.invalid && company?.touched"
          class="pygMessage">
          {{ 'freetrial.company_warn' | translate }}
        </p>

        <div class="mt-6">
          <p class="text-red-600 text-base text-center pb-2" *ngIf="status === 'error'">
            {{ 'freetrial.warn' | translate }}
          </p>

          <button
            class="mx-auto z-10 font-bold rounded-l-full rounded-r-full block w-full h-12"
            (click)="sendRequest()"
            [disabled]="status === 'pending'"
          >
            {{ (data.button ?? 'freetrial.button') | translate }}
          </button>
        </div>

        <small>{{ 'freetrial.handle_request' | translate }}
          <a [routerLink]="'/' + lang.activeLanguage + '/privacy-policy'">{{ 'freetrial.privacy_policy' | translate }}</a>
        </small>
      </form>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #successTemp>
  <div class="flex justify-center flex-col text-center">
    <img class="mx-auto w-20" src="../../../assets/img/Shared/check-circle-purple.svg" alt="check">
    <h1 class="pt-6">{{ 'success.thank_you' | translate }}</h1>
    <p class="pt-8">
      {{ 'success.request_success' | translate }}
    </p>
    <!-- <p class="pt-3.5">
      {{ 'success.give_call' | translate }}
      <b><a href="tel:+421 917 852 242">+421 917 852 242</a></b>.
    </p> -->
  </div>
</ng-template>
