import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-museums-benefits',
  templateUrl: './museums-benefits.component.html',
  styleUrls: ['./museums-benefits.component.scss']
})
export class MuseumsBenefitsComponent {

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'freetrial.title',
      place: 'Museums page - bottom section'
    });
  }
}
