import { Component } from '@angular/core';
import { TrialDialogService } from 'src/app/services/trial-dialog.service';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent {

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'freetrial.title',
      place: 'Pygmalios-X page - top section'
    });
  }
}
